<template>
  <div
    class="window"
    :style="{ 'background-image': platformSpecificImage }"
    :class="[className]"
  >
    <div class="container">
      <div class="row">
        <div class="content col col-5">
          <div>
            <slot />
          </div>
        </div>
        <div class="signup-form-wrapper col col-5 offset-2">
          <SignupFormVersion
            :title="title"
            :button-text="$c.text($config.platform === 'ds' ? 'button.start-your-career' : 'button.get-started')"
            :button-class="$config.platform === 'fa' ? 'btn-primary-yellow' : 'btn-primary'"
            type="signup"
            :unique-id="uniqueId"
            :class-name="className + ' signup-form start-learning'"
            :event-prefix="eventPrefix"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignupFormVersion from './Profile/Signup/SignupFormVersion.vue';

export default {
  components: { SignupFormVersion },
  props: {
    className: { type: String, default: '' },
    uniqueId: { type: String, default: '' },
    signupTitle: { type: String, default: '' },
    eventPrefix: { type: String, default: '' },
  },
  computed: {
    title() {
      if (this.signupTitle) {
        return this.signupTitle;
      }

      return this.$c.text('component.signUpForm.title');
    },
    platformSpecificImage() {
      if (this.$config.platform === 'fa') {
        if (this.className.includes('start-your-journey-for-free')) {
          return `url(${require('../assets/images/fa/fa-landing/fa-business-group-waving.webp')})`;
        }

        return `url(${require('../assets/images/fa/fa-signup-bg.webp')})`;
      }

      return `url(${require('../assets/images/ds-signup-bg.webp')})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.window {
  image-rendering: -webkit-optimize-contrast;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 781px;
  display: flex;
  align-items: center;
  position: relative;

  &.free-data-science-month:not(.prefooter),
  &.start-your-journey-for-free:not(.prefooter) {
    background-image: url("../assets/images/free-data-science-month.jpg");
    image-rendering: -webkit-optimize-contrast;
  }

  &.free-data-science-month:not(.prefooter) {
    height: auto;
  }

  &.start-your-journey-for-free {
    height: 802px;

    &:not(.prefooter) {
      ul {
        color: var(--main-light-shade-4);
        list-style: disc;
        list-style-position: inside;

        li {
          padding-left: 0;

          &:before {
            display: none;
          }
        }
      }
    }
  }

  .row {
    align-items: center;
  }

  .content {
    color: var(--main-light);
  }

  .signup-form {
    padding-right: 65px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: var(--spacing-24);
      padding-left: var(--spacing-36);
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 9px;
        top: 6px;
        width: 8px;
        margin-top: -9px;
        border-right: 2.2px solid var(--main-light);
        height: 18px;
        border-bottom: 2.2px solid var(--main-light);
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        border-top-color: transparent;
        border-left-color: transparent;
        border-radius: 2px;
        z-index: 1;
      }
    }
  }
}

@include bp($bp-mobile) {
  .window {
    height: auto;
    background: none;
    padding: 80px 0;
    background-image: none !important;

    &.free-data-science-month,
    &.start-your-journey-for-free {
      height: auto;
      background: none !important;
      padding: 0;

      &:before {
        display: none;
      }

      &.prefooter .content {
        background-image: url("../assets/images/signup-bg.webp");
        image-rendering: -webkit-optimize-contrast;
        background-position: 40% 100%;
        z-index: 0;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: var(--main-dark);
          opacity: 0.6;
          z-index: -1;
        }

        > div {
          text-align: left;

          h2 {
            font-size: 28px;

            br {
              display: none;
            }
          }

          .body-1 {
            font-size: 18px;
          }
        }
      }

      .container {
        padding: 0;
      }

      .row {
        margin: 0;
      }

      .content {
        display: block;
        background-image: url("../assets/images/free-data-science-month.jpg");
        image-rendering: -webkit-optimize-contrast;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 0 var(--spacing-24);
        margin: 0;

        > div {
          max-width: 420px;
          margin: 0 auto;
          padding-top: 160px;
          padding-bottom: 160px;
          text-align: center;

          .rated {
            span {
              font-size: 16px;
            }

            svg {
              width: 72px;
              margin-left: var(--spacing-4);
            }
          }

          .h1 {
            font-size: 38px;
          }

          .subtitle,
          .text {
            font-size: 18px;
          }

          .footer {
            margin-top: 46px;
          }
        }
      }

      .signup-form-wrapper {
        padding-top: var(--spacing-60);
        padding-bottom: var(--spacing-60);
      }
    }

    &.start-your-journey-for-free {
      .content {
        > div {
          padding-top: 80px;
          padding-bottom: 60px;
          text-align: left;
        }
      }
    }

    &.upcoming {
      background: var(--main-light-shade-1);

      .signup-form {
        background: var(--main-light-shade-1);
      }
    }

    .content {
      display: none;
    }

    .signup-form {
      padding: 0;
      max-width: 420px;
      margin: 0 auto;
    }
  }
}

.fa {
  .window ul li:before {
    border-color: var(--accent-light-3);
    border-radius: 0;
  }

  @include bp($bp-mobile) {
    .window {
      padding: var(--spacing-60) 0;

      &.upcoming {
        background: var(--main-light);

        .signup-form {
          background: var(--main-light);
        }
      }
    }
  }
}
</style>
