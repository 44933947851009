<template>
  <section :class="classes">
    <div class="container heading">
      <component :is="heading" class="h2" :class="titleClass">
        <slot name="title" />
      </component>
      <div class="row">
        <p class="subtitle col col-8 offset-2">
          {{ subtitle }}
        </p>
      </div>
    </div>
    <template v-if="sections.length">
      <SliderInfinite
        v-for="(s, idx) in sections"
        :key="idx"
        :class="cardSize(idx)"
        :title="s.name"
        heading="h3"
        :offset="$store.state.layout === 'desktop' ? 1 : -1"
        :disabled="sectionSlug === 'projects' && $store.state.layout !== 'mobile'"
        :align="$store.state.layout === 'desktop' ? 'container' : 'center'"
      >
        <nuxt-link
          v-for="(item, itemIdx) in fill(s.items)"
          slot="slide"
          :key="itemIdx"
          class="card"
          :class="`id-${itemIdx}`"
          :to="`/${sectionSlug}/${item.slug}`"
          :data-event="`${sectionSlug}_${s.slug}_${item.slug}.click`"
          :target="targetBlank ? '_blank' : '_self'"
        >
          <img
            :src="`${$config.imagesUrl}/home/${$config.platform === 'fa' ? 'fa-' : ''}v2-${sectionSlug}-${item.slug}.webp`" class="card-image"
            :alt="item.name || item.title" loading="lazy"
          >
          <component :is="technologyIcon(item.technology)" />
        </nuxt-link>
      </SliderInfinite>
    </template>
    <div class="section-link">
      <slot name="button" />
    </div>
  </section>
</template>

<script>
import SliderInfinite from '@itbpbg/lms-components/src/components/SliderInfinite';

export default {
  components: { SliderInfinite },
  props: {
    heading: { type: String, default: 'h2' },
    titleClass: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    classes: { type: String, default: '' },
    sections: { type: Array, default: () => ([]), required: true },
    sectionSlug: { type: String, default: '', required: true },
    sectionButtonTitle: { type: String, default: '' },
    infiniteFill: Boolean,
    targetBlank: Boolean,
  },
  methods: {
    fill(items) {
      if (this.infiniteFill && items.length < 10) {
        return [...items, ...items];
      }

      return items;
    },
    cardSize(idx) {
      if (this.$config.platform === 'ds' && idx === 1) {
        return 'home-big';
      }

      if (this.$config.platform === 'fa' && idx === 0) {
        return 'home-big';
      }

      return 'home-small';
    },
    technologyIcon(technology) {
      let tech = technology;
      if (!tech) {
        tech = 'theory';
      }

      return require(`@itbpbg/lms-components/src/assets/svg/tech-responsive-${tech.replace(' ', '-')}.svg?vue-component`);
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  .card {
    position: relative;
    transition: all var(--duration) ease-in;

    &:hover {
      transform: scale(1.06);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    svg {
      position: absolute;
      top: 16px;
      left: 16px;
      width: 24px;
      max-height: 24px;
      filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));

      [fill] {
        fill: var(--main-dark-shade-4);
      }

      [stroke] {
        stroke: var(--main-dark-shade-4);
      }
    }
  }

  .home-small .card {
    position: relative;
    width: 278px;
    height: 197px;
    overflow: hidden;
    border: 4px solid var(--main-dark-light-2);
    left: -4px; // fix positioning
    border-radius: 10px;
    margin-right: 22px;

    &:hover {
      border-color: var(--main-dark-light-4);
    }
  }

  .home-big .card {
    position: relative;
    width: 382px;
    height: 270px;
    overflow: hidden;
    border: 6px solid var(--main-dark-light-2);
    left: -6px; // fix positioning
    border-radius: 14px;
    margin-right: 18px;

    &:hover {
      border-color: var(--main-dark-light-4);
    }
  }

  ::v-deep .infinite-carousel.disabled {
    &.home-big,
    &.home-small {
      .card {
        left: 0;
      }
    }
  }

  .section-link {
    margin-top: var(--spacing-60);
    display: flex;
    justify-content: center;

    .btn-primary {
      width: 100%;
      max-width: 300px;
      text-transform: capitalize;
    }
  }
}

.container {
  text-align: center;

  .h2 {
    color: var(--main-light);
  }

  .subtitle {
    color: var(--main-light-shade-4);
    margin-top: var(--spacing-16);
    margin-bottom: 0;
  }
}

::v-deep .infinite-carousel {
  margin-top: var(--spacing-60);

  .carousel {
    position: relative;
    margin: -10px 0;
    padding: 10px 0;

    &:before,
    &:after {
      content: '';
      width: 135px;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      z-index: 1;
    }

    &:before {
      left: 0;
      background: linear-gradient(90deg, #000C1F 0%, rgba(0, 12, 31, 0) 100%);
    }

    &:after {
      right: 0;
      background: linear-gradient(270deg, #000C1F 0%, rgba(0, 12, 31, 0) 100%);
    }
  }

  &.disabled {
    .carousel {
      &:before,
      &:after {
        content: none;
      }
    }

    .card:last-child {
      margin-right: 0;
    }
  }

  .infinite-head.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--main-light);
    margin-bottom: var(--spacing-12);

    .h4 {
      text-transform: capitalize;
      margin: 0;
    }

    .controls {
      margin: 0;

      svg {
        [stroke] {
          stroke: var(--main-light-shade-4);
        }
      }
    }
  }
}

@include bp($bp-laptop) {
  section {
    .home-big .card {
      width: 322px;
      height: 234px;
    }

    .home-small .card {
      width: 233px;
      height: 176px;
    }
  }
}

@include bp($bp-mobile) {
  section {
    .home-big .card {
      width: 339px;
      height: 252px;
      left: 0;
    }

    .home-small .card {
      width: 250px;
      height: 182px;
      left: 0;
    }

    .section-link .btn-primary {
      max-width: 327px;
    }
  }

  ::v-deep .infinite-carousel .carousel {
    &:before,
    &:after {
      content: none;
    }
  }
}
</style>
