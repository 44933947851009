<template>
  <div>
    <div class="home-head">
      <div class="container">
        <div class="row">
          <div class="col col-5">
            <h1 v-html="$c.text('pages.index.heroTitle')" />
            <p class="subtitle" :class="{ 'body-2': $store.state.layout === 'mobile' }">
              {{ $c.text('pages.index.heroDescription') }}
            </p>
            <nuxt-link
              v-if="$config.platform === 'ds'"
              to="/signup/" :class="$config.platform === 'fa' ? 'btn-primary-light' : 'btn-primary'" class="hero-button btn-lg col-9"
              data-event="start_your_career_1.click"
            >
              {{ $c.text('button.start-your-career') }}
            </nuxt-link>
            <div class="rating-holder">
              <Rating v-if="$config.platform === 'ds'" :scale="4.5" />
              <RatingStars v-else :rating="4.5" />
              <span class="text">
                <strong>{{ $config.platform === 'ds' ? '413,000+' : '285,797+' }}</strong> {{ $c.text('reviews') }}
              </span>
            </div>
          </div>
          <div v-if="$config.platform === 'fa'" class="signup-form-wrapper col col-5 offset-2">
            <SignupFormVersion
              :first-step-title="$c.text('start-learning-for-free')"
              :title="$c.text('component.signUpForm.title')"
              :button-text="$c.text('button.get-started')"
              button-class="btn-primary-yellow"
              type="signup"
              unique-id="first"
              class="signup-form form-desktop"
            />
          </div>
        </div>
      </div>
      <div v-if="$config.platform === 'ds'" class="animation">
        <HeroAnimation />
      </div>
    </div>
    <SignupFormVersion
      v-if="$config.platform === 'fa'"
      :first-step-title="$c.text('start-learning-for-free')"
      :title="$c.text('component.signUpForm.title')"
      :button-text="$c.text('button.start-your-career')"
      button-class="btn-primary-yellow"
      type="signup"
      unique-id="second"
      class="signup-form form-mobile"
    />
    <Lazy never>
      <div class="tips tracking-section" data-event="get_ready_for_your_dream_job.scroll">
        <div class="container">
          <h2 class="col-6 h2" v-html="$c.text('pages.index.tips.title')" />
          <ol class="row">
            <li
              v-for="(v, i) in tips" :key="i" class="col col-3 md-4"
              :class="{ 'md-offset-2': [0, 2].includes(i), 'top-spacing': [2, 3].includes(i) }"
            >
              <div class="content">
                <p class="title h5">
                  {{ v.title }}
                </p>
                <span class="description body-1">{{ v.description }}</span>
                <span class="number">{{ i + 1 }}</span>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </Lazy>
    <Lazy never>
      <GraduatesWork class="site-section" :home="false" />
    </Lazy>
    <Lazy :when-visible="{ rootMargin: '200px' }">
      <SliderTracks
        class="tracks site-section tracking-section" :tracks="tracks"
        data-event="choose_the_best_career_track_for_you.scroll"
      />
    </Lazy>
    <Lazy :when-visible="{ rootMargin: '200px' }">
      <EasySteps
        :title="easyStepsTitle"
        :subtitle="easyStepsSubtitle"
        :options="easyStepsOptions"
        class="tracking-section"
        data-event="four_easy_steps.scroll"
      />
    </Lazy>
    <Lazy never>
      <PreFooter
        v-if="$config.platform === 'ds'"
        :title="$c.text('pages.index.preFooterTitle')"
        :description="$c.text('pages.index.preFooterDescription')"
        :button-title="$c.text('button.start-your-career')"
        button-link="/signup/"
        :dark="true"
      />
      <FaPreFooter
        v-else
        :title="$c.text('pages.index.preFooterTitle')"
        :description="$c.text('pages.index.preFooterDescription')"
        :button-title="$c.text('button.start-your-career')"
        columns="col-8"
        class-name="top-shadow"
      />
    </Lazy>
    <div v-if="courses.length" class="featured-slider">
      <div class="courses-cards container">
        <Slider class="courses-slider" class-name="featured-courses">
          <h2 slot="heading" class="courses-header h2">
            Featured Courses
          </h2>
          <div
            v-for="(course, idx) in courses"
            slot="slide"
            :key="idx"
            class="slide col col-12"
          >
            <img
              loading="lazy"
              class="slide-background"
              :src="`${$webp(`${$config.imagesUrl}/courses/${course.bigImage}`)}`"
              :alt="course.instructors"
            >
            <div class="slide-container col-5 offset-1">
              <div class="slide-header">
                <div v-if="course.badge" class="badge">
                  <Component :is="featuredIcon[course.badge]" />
                  {{ course.badge }}
                </div>

                <h3 :title="course.name" class="h3">
                  {{ course.name }}
                </h3>
                <div class="instructors">
                  with {{ course.instructors }}
                </div>
                <CourseRating v-if="course.reviews" :reviews="course.reviews" />
              </div>
              <div class="info-container">
                <p>{{ course.descriptionShort }}</p>
                <div class="info">
                  <div>
                    {{ course.duration }} Hours
                  </div>
                  <div>
                    {{ course.lessons }} Lessons
                  </div>
                  <div>
                    Certificate
                  </div>
                </div>
              </div>
              <nuxt-link :to="`${$config.project === 'site' ? '/signup/' : `/courses/${course.slug}/`}`" class="btn btn-primary start-course">
                Start Course
              </nuxt-link>
            </div>
          </div>
        </Slider>
      </div>
    </div>
    <Lazy v-if="$config.platform === 'ds'" :when-visible="{ rootMargin: '200px' }">
      <InstructorsCards :instructors="instructors" class="tracking-section" data-event="instructors.scroll" />
    </Lazy>
    <Lazy never>
      <Reviews class="tracking-section" data-event="reviews.scroll" />
    </Lazy>
    <Lazy :when-visible="{ rootMargin: '200px' }">
      <StartLearningToday
        v-if="$config.project !== 'lms'"
        new-version
        class="start-learn-today tracking-section"
        data-event="founders_note.scroll"
      >
        <h4 class="h3">
          {{ $c.text('pages.index.startLearningComponent.title') }}
        </h4>
        <p class="body-1" v-html="$c.text('pages.index.startLearningComponent.description')" />
        <div class="founder">
          <img loading="lazy" src="../../assets/images/founder-image.png" alt="Nedko Krastev">
          <div class="name">
            <p class="h6">
              {{ $c.text('pages.index.startLearningComponent.name') }}
            </p>
            <p class="caption">
              {{ $c.text('pages.index.startLearningComponent.caption') }}
            </p>
          </div>
          <img
            class="sign"
            loading="lazy" src="../../assets/images/founder-sign.png"
            alt="Nedko Krastev's sign"
          >
        </div>
      </StartLearningToday>
    </Lazy>
    <SignupFormVersion
      v-if="$config.platform === 'fa'"
      :title="$c.text('component.signUpForm.title')"
      :button-text="$c.text('button.get-started')"
      button-class="btn-primary-yellow"
      type="signup"
      unique-id="third"
      class="signup-form form-mobile"
    />
  </div>
</template>

<script>
import Lazy from 'vue-lazy-hydration';
import global from '@itbpbg/lms-components/src/mixins/global';
import Slider from '@itbpbg/lms-components/src/components/Slider';
import Rating from '@itbpbg/lms-components/src/Rating';
import CourseRating from '@itbpbg/lms-components/src/Courses/Rating';
import RatingStars from '@itbpbg/lms-components/src/Courses/RatingStars';
import Reviews from '@itbpbg/lms-components/src/Reviews';
import StartLearningToday from '../StartLearningToday';
import GraduatesWork from '../GraduatesWork';
import SliderTracks from '../SliderTracks';
import PreFooter from '../PreFooter';
import InstructorsCards from '../InstructorsCards';
import HeroAnimation from '../Animations/HomeHeroSection';
import EasySteps from '../Landing/EasySteps';
import StaffPickIcon from '../../assets/svg/icon-staff-pick-courses.svg?vue-component';
import FoundationalIcon from '../../assets/svg/icon-foundational-courses.svg?vue-component';
import FreshIcon from '../../assets/svg/icon-fresh-courses.svg?vue-component';
import ExcitingIcon from '../../assets/svg/icon-exciting-courses.svg?vue-component';
import EngagingIcon from '../../assets/svg/icon-engaging-courses.svg?vue-component';
import OriginalIcon from '../../assets/svg/icon-original-courses.svg?vue-component';
import FaPreFooter from '../FaPreFooter';
import SignupFormVersion from '../Profile/Signup/SignupFormVersion';

export default {
  components: {
    Lazy,
    StartLearningToday,
    Rating,
    GraduatesWork,
    SliderTracks,
    PreFooter,
    InstructorsCards,
    Reviews,
    HeroAnimation,
    EasySteps,
    Slider,
    CourseRating,
    SignupFormVersion,
    RatingStars,
    FaPreFooter,
  },
  mixins: [global],
  props: {
    data: { type: Object, default: () => ({}), required: true },
  },
  data() {
    return {
      tips: [
        {
          title: this.$c.text('pages.index.tips.1.title'),
          description: this.$c.text('pages.index.tips.1.description'),
        },
        {
          title: this.$c.text('pages.index.tips.2.title'),
          description: this.$c.text('pages.index.tips.2.description'),
        },
        {
          title: this.$c.text('pages.index.tips.3.title'),
          description: this.$c.text('pages.index.tips.3.description'),
        },
        {
          title: this.$c.text('pages.index.tips.4.title'),
          description: this.$c.text('pages.index.tips.4.description'),
        },
      ],
      easyStepsTitle: this.$c.text('pages.index.fourEasySteps.title'),
      easyStepsSubtitle: this.$c.text('pages.index.fourEasySteps.subtitle'),
      easyStepsOptions: [
        {
          title: this.$c.text('pages.index.fourEasySteps.step1'),
          subtitle: this.$c.text('pages.index.fourEasySteps.step1.subtitle'),
          animation: 'animationTrack',
          arrowbtnProps: {
            type: 'span',
            to: '/career-tracks/',
            text: 'Learn More',
            title: 'Learn more about Career Tracks',
            'data-event': 'four_easy_steps_career_tracks.click',
          },
        },
        {
          title: this.$c.text('pages.index.fourEasySteps.step2'),
          subtitle: this.$c.text('pages.index.fourEasySteps.step2.subtitle'),
          animation: 'animationExams',
          arrowbtnProps: {
            type: 'a',
            rel: 'nofollow',
            href: `${this.$config.lmsUrl}/exams/`,
            text: 'Learn More',
            'data-event': 'four_easy_steps_exams.click',
          },
        },
        {
          title: this.$c.text('pages.index.fourEasySteps.step3'),
          subtitle: this.$c.text('pages.index.fourEasySteps.step3.subtitle'),
          animation: 'animationCertificate',
          arrowbtnProps: {
            type: 'span',
            to: this.$config.platform === 'ds' ? '/course-certificate/' : '/certificate/',
            text: 'Learn More',
            title: 'Learn more about Course certificates',
            'data-event': 'four_easy_steps_certificates.click',
          },
        },
        {
          title: this.$c.text('pages.index.fourEasySteps.step4'),
          subtitle: this.$c.text('pages.index.fourEasySteps.step4.subtitle'),
          animation: 'animationResume',
          arrowbtnProps: {
            type: 'a',
            rel: 'nofollow',
            href: this.$config.lmsUrl,
            text: 'Learn More',
            'data-event': 'four_easy_steps_resume.click',
          },
        },
      ],
      featuredIcon: {
        Exciting: ExcitingIcon,
        Foundational: FoundationalIcon,
        Fresh: FreshIcon,
        Engaging: EngagingIcon,
        Original: OriginalIcon,
        'Staff pick': StaffPickIcon,
      },
    };
  },
  computed: {
    page() {
      return this.data.page || {};
    },
    instructors() {
      return this.data.instructors || [];
    },
    tracks() {
      return this.data.tracks || [];
    },
    courses() {
      return this.data.courses || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.featured-slider {
  .courses-cards {
    padding: var(--spacing-100) 0 0;

    .courses-header {
      margin-bottom: 0;
    }

    ::v-deep .row.swiper {
      margin: 0;
    }

    ::v-deep .slider-container {
      padding: 0;
      margin-left: var(--spacing-10);
      margin-right: var(--spacing-10);
    }

    .slide {
      height: 500px;
      display: flex;
      align-items: center;
      background: var(--main-dark);
      border-radius: 10px;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 200px;
        background: linear-gradient(180deg, rgba(0, 12, 31, 0.6) 0%, rgba(0, 12, 31, 0) 100%);
        border-radius: 7px;
        transform: rotate(-180deg);
        pointer-events: none;
      }

      .slide-background {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        image-rendering: -webkit-optimize-contrast;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        z-index: 0;
        object-fit: cover;
        object-position: center;
        display: block;
      }

      .slide-container {
        margin-left: 0;
        padding-left: var(--spacing-60);
        color: var(--main-light);
        position: relative;

        .slide-header {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .badge {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 5px 10px;
            background: rgba(237, 200, 67, 0.2);
            border-radius: 5px;
            color: #EDC843;

            svg {
              margin-right: var(--spacing-8);
            }
          }

          .h3 {
            margin-top: 12px;
            margin-bottom: 0;
            font-weight: 400;
            text-transform: capitalize;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            display: block;
            display: -webkit-box;
          }
        }

        .info {
          display: flex;
          align-items: center;

          > div {
            display: flex;
            align-items: center;
            position: relative;

            &:not(:last-child) {
              margin-right: var(--spacing-36);

              &:after {
                content: "";
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: var(--main-dark-light-4);
                position: absolute;
                right: -18px;
                top: 8px;
              }
            }
          }
        }

        .start-course {
          position: relative;
          z-index: 1;
          cursor: pointer;
          font-weight: 600;
          text-decoration: none;
          max-width: 300px;
          width: 100%;
          text-align: center;
          height: 58px;
          line-height: 58px;
          font-size: 18px;
          padding: 0;
        }
      }

      .info-container {
        margin-top: var(--spacing-24);
        margin-bottom: var(--spacing-48);
      }

      p {
        color: var(--main-light-shade-4);
        font-size: 18px;
        line-height: 27px;
        margin-bottom: var(--spacing-16);
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: block;
        display: -webkit-box;
      }
    }
  }
}

.home-head {
  background-color: var(--main-dark);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(253.23deg, rgba(0, 164, 178, 0.2) 0%, rgba(0, 164, 178, 0.05) 44.75%, rgba(0, 164, 178, 0) 94.77%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    z-index: 0;
  }

  .container {
    .row {
      height: 760px;
      align-items: center;
    }

    h1 {
      padding-top: 43px;
      color: var(--main-light);
      margin: 0 0 var(--spacing-16);
    }

    .subtitle {
      margin: 0;
      color: var(--main-light);
      opacity: 0.67;
    }

    .hero-button {
      margin-top: var(--spacing-60);
      display: block;
      text-align: center;
    }

    .rating-holder {
      margin-top: var(--spacing-16);
      display: flex;
      align-items: center;

      .text {
        margin-left: var(--spacing-12);
        color: var(--main-light);
        opacity: 0.67;
      }
    }
  }

  .animation {
    position: absolute;
    top: 0;
    right: var(--spacing-16);
    width: 45%;
    height: 100%;
    z-index: 1;
    overflow: hidden;

    &:before {
      content: "";
      background: linear-gradient(180deg, var(--main-dark) 0%, rgba(0, 12, 31, 0.76) 37.5%, rgba(0, 12, 31, 0) 100%);
      position: absolute;
      top: 0;
      width: 100%;
      height: 160px;
      z-index: 1;
      pointer-events: none;
    }

    &:after {
      content: "";
      background: linear-gradient(0deg, var(--main-dark) 0%, rgba(0, 12, 31, 0.7) 41.67%, rgba(0, 12, 31, 0) 100%);
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 160px;
      z-index: 1;
      pointer-events: none;
    }
  }
}

.tips {
  margin-top: var(--spacing-100);

  h2 {
    margin: 0 auto;
    text-align: center;
  }

  ol {
    margin-top: var(--spacing-60);
    padding: 0;
    list-style: none;

    li {
      .content {
        position: relative;
        padding-left: calc(var(--spacing-16) + 14px);
        padding-top: var(--spacing-20);
        border-left: 1px solid var(--main-light-shade-3);
        margin-left: 14px;
        height: 100%;

        .h5 {
          margin-top: 0;
          margin-bottom: var(--spacing-16);
        }

        .body-1 {
          display: block;
          padding-right: var(--spacing-36);
          color: var(--main-dark-shade-3);
        }

        .number {
          width: 28px;
          height: 28px;
          line-height: 110%;
          display: block;
          color: var(--main-light);
          background-color: var(--accent-shade-1);
          border-radius: 50%;
          text-align: center;
          position: absolute;
          padding-top: 5px;
          top: 0;
          left: -14px;
        }
      }
    }
  }
}

.start-learn-today {
  * {
    color: var(--main-light);
  }

  .content {
    color: var(--main-light);
  }

  .h3 {
    margin: 0;
  }

  .body-1 {
    margin-top: 33px;
    white-space: pre-line;
    padding-right: 25px;
  }

  .founder {
    margin-top: var(--spacing-36);
    display: flex;
    align-items: center;

    .name {
      margin-right: var(--spacing-24);
    }

    p {
      margin: 0;

      &.h6 {
        margin-bottom: var(--spacing-8);
      }
    }

    img:not(.sign) {
      margin-right: var(--spacing-16);
    }

    .sign {
      margin: 0;
      width: 125px;
    }
  }
}

.steps {
  margin-top: 130px;
}

@include bp($bp-laptop) {
  .featured-slider .courses-cards .slide .slide-container .start-course {
    max-width: 225px;
  }

  .tips ol li {
    .content .body-1 {
      padding-right: 0;
    }

    &.top-spacing {
      margin-top: var(--spacing-100);
    }
  }
}

@include bp($bp-mobile) {
  .home-head {
    &:before {
      content: none;
    }

    .container {
      h1 {
        padding-top: 0;
      }

      .hero-button {
        margin-top: var(--spacing-48);
      }

      &:before {
        content: '';
        z-index: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(225.11deg, rgba(0, 164, 178, 0.14) 19.77%, rgba(0, 164, 178, 0.05) 36.77%, rgba(0, 12, 31, 0) 63.97%);
        transform: matrix(-1, 0, 0, 1, 0, 0);
      }

      .row {
        height: auto;
        padding: 168px 0 var(--spacing-120);

        .col {
          max-width: 500px;
          margin: 0 auto;
        }
      }
    }

    .animation {
      position: relative;
      right: 0;
      width: 100%;
      padding: 0 24px;
    }
  }

  .tips {
    margin: var(--spacing-80) 0 var(--spacing-26);

    h2 {
      width: auto;
      text-align: left;
      text-transform: capitalize;
    }

    ol {
      margin-top: var(--spacing-36);
      padding-left: var(--spacing-44);

      li {
        .content {
          .h5 {
            margin-bottom: var(--spacing-12);
          }

          .number {
            padding-right: 1px;
          }
        }
      }

      li + li {
        margin-top: var(--spacing-24);
      }
    }
  }

  .featured-slider .courses-cards {
    .courses-header {
      padding-left: 24px;
    }

    .slide {
      height: 740px;
      border-radius: 0;
      padding: 64px var(--spacing-24);
      background-size: 180%;
      align-items: flex-end !important;

      &:after {
        background: linear-gradient(180deg, var(--main-dark) 0%, rgba(0, 12, 31, 0.94) 0.01%, var(--main-dark) 61.47%, rgba(0, 12, 31, 0) 91.61%);
        border-radius: 0;
        height: 550px;
        bottom: 14px;
        z-index: -1;
      }

      .info > div {
        font-size: 14px !important;
        margin-right: 20px;
        font-weight: 500;

        &:last-child {
          margin-right: 0;
        }

        svg {
          width: 20px;
        }
      }

      .slide-background {
        height: 450px;
        background-position: 77% 0;
        border-radius: 0;

        &:after {
          content: "";
          background: linear-gradient(180deg, var(--main-dark) 0%, rgba(0, 12, 31, 0) 100%);
          transform: rotate(-180deg);
          position: absolute;
          height: 150px;
          width: 100%;
          bottom: -2px;
        }
      }

      .slide-container {
        padding: 0;

        p {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

@include bp($bp-tablet) {
  .home-head .animation {
    height: 750px;
  }

  .featured-slider .courses-cards .slide .slide-background {
    background-size: 160%;
    background-position: 100% 0;
  }
}

@include bp($bp-phone) {
  .home-head .animation {
    height: 638px;
    padding: 0 12px;
    overflow: hidden;
  }

  .featured-slider .courses-cards {
    .slide {
      background-size: 200%;
      background-position: 93% 0;

      .slide-background {
        object-position: 75%;
      }

      .slide-container .start-course.btn {
        width: 100%;
      }

      .continue-course {
        width: 100%;
      }
    }
  }

  .steps {
    margin-top: 0;
  }
}

.fa {
  @import '../../assets/sass/fa/pages/homepage.scss';
}
</style>
