<template>
  <a
    href="#social-login"
    :title=" formType === 'login' ? 'Login with Facebook' : 'Sign up with Facebook'"
    :class="{ 'no-bg': buttonText }"
    rel="nofollow"
    v-on="formType === 'login' ? { click: logInWithFacebook } : { click: socialSignUp }"
  >
    <template v-if="buttonText">
      <IconFacebook /> {{ buttonText }}
    </template>
  </a>
</template>
<script>
import IconFacebook from '../assets/svg/icon-colored-fb.svg?vue-component';
import { sha256 } from '../utils/helper';

export default {
  components: {
    IconFacebook,
  },
  props: {
    keepMeLoggedIn: Boolean,
    errored: Boolean,
    campaign: Boolean,
    termsAgreed: Boolean,
    resources: Boolean,
    formType: { type: String, default: '' },
    buttonText: { type: String, default: '' },
    flashcards: Boolean,
  },
  async mounted() {
    await this.loadFacebookSDK(document, 'script', 'facebook-jssdk');
    await this.initFacebook();
  },
  methods: {
    async logInWithFacebook() {
      await this.$root.$emit('check');
      if (!this.errored) {
        const { event } = this.$el.dataset;
        const fbToken = Object.keys(localStorage).find((k) => k.includes('fblst_'));

        if (fbToken) {
          localStorage.removeItem(fbToken);
        }

        window.FB.login(
          async (response) => {
            if (response.status === 'connected') {
              const { accessToken, userID } = response.authResponse;

              this.$tracking.setFBuid(userID);

              const request = await this.$axios.$post(`/auth/social/facebook?keepMeLoggedIn=${!!this.keepMeLoggedIn}&beta=${!!this.$config.gateway}&campaign=${this.campaign}`, { token: accessToken, course: this.$route.query.course });
              if (request && request.businessAccount) {
                this.$store.dispatch('auth/setUserInfo', request);
              }

              if (request && request.user) {
                if (request.user.registered) {
                  const eventID = `${this.$store.state.tracking.sessionId}${Date.now()}`;
                  dataLayer.push({ event: 'FreeSignup', signupType: 'Facebook', eventID });

                  window.FB.getLoginStatus((res) => {
                    if (response.status === 'connected') {
                      // the user is logged in and connected to your
                      // app, and response.authResponse supplies
                      // the user’s ID, a valid access token, a signed
                      // request, and the time the access token
                      // and signed request each expire
                      const uid = res.authResponse.userID;
                      this.$tracking.setFBuid(uid);
                    } else if (res.status === 'not_authorized') {
                      // the user is logged in to Facebook,
                      // but not connected to the app
                    } else {
                      // the user isn't even logged in to Facebook.
                    }
                  });

                  console.log(request);

                  if (request.user?.email) {
                    dataLayer.push({
                      user_data: {
                        email: request.user.email.trim(),
                        sha256_email_address: await sha256(request.user.email.trim()),
                      },
                    });
                  }

                  this.$tracking.sendEvent({
                    dataLayerEvent: 'SignUp',
                    destinationUrl: this.$router.currentRoute.fullPath,
                    eventID,
                    fbLoginId: userID,
                    capi: true,
                    userAgent: navigator.userAgent,
                  });
                }

                this.$store.dispatch('auth/setUserInfo', request.user);
                if (this.resources) {
                  this.$emit('continueToExamResult');
                  return;
                }
                if (this.campaign) {
                  this.$root.$emit('social-login-success');
                } else {
                  await this.$tracking.sendAsyncEvent(event, { status: 'success' });

                  if (this.$route.query.course) {
                    if (this.$store.getters['auth/user'].simpleLms) {
                      this.$redirectAfterLogin('/');
                    } else {
                      this.$redirectAfterLogin('/courses/?tab=my-courses');
                    }
                  } else if (!this.flashcards) {
                    this.$redirectAfterLogin('/my-learning-path/');
                  }
                }
              }

              if (request && request.error) {
                this.$tracking.sendAsyncEvent(event, { status: 'fail', error: request.description || request.error });
              }
            } else if (event) {
              this.$tracking.sendAsyncEvent(event, { status: 'fail', error: `${response.authResponse ? `${response.authResponse}-` : ''}${response.status || ''}` });
            }
          },
          { scope: 'public_profile, email' },
        );
      }
      return false;
    },

    async initFacebook() {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: this.$config.facebookApiKey,
          cookie: true,
          version: 'v9.0',
        });
      };
    },

    async loadFacebookSDK(d, s, id) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      // eslint-disable-next-line prefer-const
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    },
    checkTerms() {
      this.$root.$emit('check-terms', true);
    },
    socialSignUp() {
      if (this.termsAgreed) {
        this.logInWithFacebook();
      }
      this.checkTerms();
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  background-image: url('../assets/svg/icon-colored-fb.svg');
  image-rendering: -webkit-optimize-contrast;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0;
  color: var(--main-dark-shade-0);
  display: block;
  line-height: 50px;
  height: 100%;
  text-align: center;
  width: 100%;

  &.no-bg {
    background-image: none;
  }
}
</style>
